<template>
    <div>
        <validation-observer ref="surveyForm" #default="{ invalid }">
            <div class="mt-2">
                <b-row>
                    <b-col md="3">
                        <b-form-group>
                            <label class="h3">
                                <b>Title</b><span class="text-danger">*</span>
                            </label>
                            <validation-provider #default="{ errors }" name="Title" rules="required">
                                <b-form-input class="mb-1" v-model="questionnaireTitle" type="text"
                                    placeholder="Questionnaire Title" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group>
                            <label class="h3">
                                <b>Description</b>
                            </label>
                            <b-form-input class="mb-1" v-model="questionnaireSubtitle" type="text"
                                placeholder="Questionnaire Description" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group>
                            <label class="h3">
                                <b>Type</b><span class="text-danger">*</span>
                            </label>

                            <validation-provider #default="{ errors }" name="Type" rules="required">
                                <v-select v-model="selectedQuestionnaireType" :options="questionnaireTypes"
                                    placeholder="Select Questionnaire Type" :state="errors.length > 0 ? false : null"
                                    :reduce="type => type.value" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group>
                            <label class="h3">
                                <b>Assigned Levels</b>
                            </label>
                            <v-select :disabled="!selectedQuestionnaireType" v-model="selectedLevels" :options="levels"
                                multiple placeholder="Select Levels" :reduce="level => level.value"></v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <b-form ref="form" class="repeater-form" @submit.prevent="createQuestionnaire">
                <div v-for="(competency, index) in competencies" :key="index" class="mb-3">
                    <b-card>
                        <template #header>
                            <b-card-title class="">
                                <b>{{ competency.title }}</b>
                            </b-card-title>
                            <b-col md="4"
                                v-if="!(competency.isDefaultProjectQuestion || department == 'partners' || competency.title === 'General Questions')">
                                <b-form-group>
                                    <b-input-group :append="`${competency.percentage}%`">
                                        <template #prepend>
                                            <b-input-group-text>
                                                Weight<span class="text-danger">*</span>
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input min="0" max="100" step="1" v-model="competency.percentage"
                                            type="range" placeholder="100" />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </template>
                        <draggable tag="ul" class="list-group list-group-flush cursor-move" :list="competency.items">
                            <transition-group type="transition" name="flip-list">
                                <b-row v-for="(item, idx) in competency.items" :key="item.id" tag="li"
                                    class="align-items-center">
                                    <b-col md="4">
                                        <b-form-group>
                                            <label class="lead">Question Title<span class="text-danger">*</span></label>
                                            <validation-provider #default="{ errors }" name="Question Title"
                                                rules="required">
                                                <b-form-input v-model="item.title" type="text"
                                                    placeholder="Enter question title"
                                                    :disabled="competency.isDefaultProjectQuestion"
                                                    :state="errors.length > 0 ? false : null" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="4">
                                        <b-form-group>
                                            <label class="lead">Question Description</label>
                                            <b-form-input v-model="item.description"
                                                :disabled="competency.isDefaultProjectQuestion" type="text"
                                                placeholder="Enter question description" />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="2" v-if="!competency.isDefaultProjectQuestion">
                                        <b-form-group>
                                            <label class="lead">Question Type<span class="text-danger">*</span></label>
                                            <v-select v-model="item.type" :options="questionTypes"
                                                placeholder="Select Type" :reduce="type => type.value"></v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="1" class="text-center" v-if="!competency.isDefaultProjectQuestion">
                                        <b-form-group>
                                            <label class="lead mb-50">Required<span class="text-danger">*</span></label>
                                            <b-form-checkbox v-model="item.required" class="custom-control-success"
                                                switch>
                                                <span class="switch-icon-left">
                                                    <feather-icon icon="CheckIcon" />
                                                </span>
                                                <span class="switch-icon-right">
                                                    <feather-icon icon="XIcon" />
                                                </span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="1" class="text-center">
                                        <b-button v-if="idx > 0 && !competency.isDefaultProjectQuestion"
                                            class="p-25 mt-1" variant="outline-danger" size="sm"
                                            @click="removeItem(index, idx)">
                                            <feather-icon icon="XIcon" />
                                        </b-button>
                                    </b-col>
                                    <b-col cols="12">
                                        <hr />
                                    </b-col>
                                </b-row>
                            </transition-group>
                        </draggable>
                        <b-button variant="primary" @click="addItem(index)" v-if="!competency.isDefaultProjectQuestion">
                            <feather-icon icon="PlusIcon" class="mr-1" />
                            <span>Add New Question</span>
                        </b-button>
                    </b-card>
                </div>
            </b-form>
            <div>
                <b-button variant="success" class="ml-2 mb-3 px-3" :disabled="invalid || loading"
                    @click="createQuestionnaire">
                    <span style="font-size: 1.4rem;" class="lead"><b>Create Questionnaire</b></span>
                </b-button>
            </div>
        </validation-observer>
    </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import { BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BFormSelect, BFormCheckbox, BCardTitle, BInputGroup, BInputGroupText, BInputGroupPrepend } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        draggable,
        BCard,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormCheckbox,
        BCardTitle,
        BInputGroup,
        BInputGroupText,
        BInputGroupPrepend,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    watch: {
        selectedQuestionnaireType:
        {
            handler(newType) {
                if (newType) {
                    this.selectedLevels = []
                    const department = this.department
                    const type = newType
                    this.$http.get(`/levels/type/${type}/department/${department}`).then((res) => {
                        this.levels = res.data.map(level => ({ label: level.name, value: level._id }));
                    })
                } else {
                    this.selectedLevels = []
                }
                console.log(this.department)
                if (newType === 'projectEval' && this.department != 'partners') {
                    this.addDefaultCompetency();
                } else {
                    this.removeDefaultCompetency();
                }
            },
            deep: true,
            immediate: true,
        }
    },
    data() {
        return {
            required,
            loading: false,
            department: router.currentRoute.params.department,
            questionnaireTitle: '',
            questionnaireSubtitle: '',
            selectedLevels: [],
            selectedQuestionnaireType: null,
            levels: [
            ],
            questionnaireTypes: [
                { value: 'eval360', label: 'Peer Evaluation' },
                { value: 'projectEval', label: 'End of Project Evaluation' },
                { value: 'selfEval', label: 'Self Evaluation' },
            ],
            competencyNames: [],
            competencies: [
            ],
            nextQuestionID: 1,
            questionTypes: [
                { value: 'text', label: 'Text' },
                { value: 'rating', label: 'Rating' },
            ],
            initialPercentage: 0,
            isPercentageValid: true,
        }
    },
    computed: {
        totalPercentage() {
            const total = this.competencies.reduce((total, competency) => total + parseFloat(competency.percentage), 0);
            return total;
        },
    },
    methods: {
        initializeCompetencies() {
            this.competencies = this.competencyNames.map(name => ({
                title: name,
                isDefaultProjectQuestion: false,
                percentage: this.initialPercentage,
                items: [
                    {
                        id: this.nextQuestionID++,
                        title: '',
                        description: '',
                        type: 'rating',
                        required: true,
                    }
                ]
            }));
            if (this.department === 'partners') {
                this.competencies[0].percentage = 100
            }
        },
        addDefaultCompetency() {
            // Check if there is already a default competency
            const existingDefaultIndex = this.competencies.findIndex(comp => comp.isDefaultProjectQuestion);

            if (existingDefaultIndex === -1) {
                // Add the default competency
                this.competencies.push({
                    isDefaultProjectQuestion: true,
                    title: 'Readiness for promotion',
                    percentage: 0,
                    items: [
                        {
                            id: this.nextQuestionID++,
                            title: 'How would you assess this person’s readiness for the next level?',
                            description: 'This is a required question in end of project evaluations.',
                            type: 'rating',
                            required: true,
                        },
                        {
                            id: this.nextQuestionID++,
                            title: 'Comments',
                            description: "Additional comments on this person's readiness to the next level.",
                            type: 'text',
                            required: false,
                        }
                    ]
                });
            } else {
                // Optional: Notify the user or handle the case where a default competency already exists
                console.warn('Default competency already exists.');
            }
        },
        removeDefaultCompetency() {
            const index = this.competencies.findIndex(comp => comp.isDefaultProjectQuestion);
            if (index !== -1) {
                this.competencies.splice(index, 1);
            }
        },
        addItem(competencyIndex) {
            this.competencies[competencyIndex].items.push({
                id: this.nextQuestionID++,
                title: '',
                description: '',
                type: 'rating',
                required: false,
            })
        },
        removeItem(competencyIndex, questionIndex) {
            if (questionIndex > 0) {
                this.competencies[competencyIndex].items.splice(questionIndex, 1);
            }
        },
        createQuestionnaire() {
            if (this.totalPercentage !== 100) {
                this.isPercentageValid = false;
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Total weights must be 100%!',
                        text: `Please change the weights. Total weight ${this.totalPercentage}%`,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return;
            }

            this.loading = true;
            this.isPercentageValid = true;
            const questionnaireJson = {
                title: this.questionnaireTitle,
                subtitle: this.questionnaireSubtitle,
                levels: this.selectedLevels,
                questionnaireType: this.selectedQuestionnaireType,
                competencies: this.competencies.map(competency => ({
                    isDefaultProjectQuestion: competency.isDefaultProjectQuestion,
                    title: competency.title,
                    percentage: competency.percentage,
                    items: competency.items.map(item => ({
                        title: item.title,
                        description: item.description,
                        type: item.type,
                        isRequired: item.required,
                        rateCount: item.type === 'rating' ? 4 : undefined,
                        rateMax: item.type === 'rating' ? 4 : undefined,
                    })),
                })),
            }

            const surveyJSFormat = this.convertToSurveyJSFormat(questionnaireJson)
            // console.log('QuestionnaireJS Format:', surveyJSFormat)

            const requestData = this.prepareRequestData(questionnaireJson, surveyJSFormat)

            this.$http.post('/questionnaires', requestData)
                .then(response => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Questionnaire created successfully!',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    router.push({ name: 'questionnaires-list' })
                })
                .catch(err => {
                    const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Error creating questionnaire!',
                            text: errorMessage,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                    console.error('Error creating questionnaire:', err)
                })
        },
        prepareRequestData(questionnaireJson, surveyJSFormat) {
            const weightMap = this.competencies.reduce((map, competency) => {
                map[competency.title] = parseFloat(competency.percentage)
                return map
            }, {})
            return {
                title: questionnaireJson.title,
                body: surveyJSFormat,
                department: this.department,
                type: questionnaireJson.questionnaireType,
                weights: weightMap,
                levels: questionnaireJson.levels
            }
        },
        convertToSurveyJSFormat(questionnaireData) {
            return {
                title: questionnaireData.title,
                description: questionnaireData.subtitle,
                showProgressBar: "belowHeader",
                progressBarType: "pages",
                // progressBarShowPageNumbers: true,
                progressBarShowPageTitles: true,
                pages: questionnaireData.competencies.map((competency, index) => ({
                    isDefaultProjectQuestion: competency.isDefaultProjectQuestion,
                    name: `page${index + 1}`,
                    navigationTitle: competency.title,
                    elements: competency.items.map((item, idx) => ({
                        type: item.type,
                        name: `q${index + 1}_${idx + 1}`,
                        title: item.title,
                        description: item.description,
                        isRequired: item.isRequired,
                        rateCount: item.type === 'rating' ? 4 : undefined,
                        rateMax: item.type === 'rating' ? 4 : undefined
                    })),
                    title: competency.title,
                    // description: `${competency.percentage}%`
                }))
            }
        },
    },
    created() {
        // Check if route is correct
        const departments = ['advisors', 'implement', 'operations', 'partners']
        if (!departments.includes(this.department)) {
            router.push({ name: 'not-found' })
            return;
        }

        // Fetch competencies
        this.$http.get(`/competencies/${this.department}`)
            .then(response => {
                this.competencyNames = response.data.competencies;
                this.initializeCompetencies(); // Call method to initialize competencies based on names
            })
            .catch(error => {
                console.error('Error fetching competencies:', error);
            });

        // Total Percentage Validator
        extend('totalPercentage', {
            validate: value => {
                return value === 100;
            },
            message: 'Total percentage must be 100%.'
        });
    }
}
</script>

<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}

.b-form-group {
    margin-bottom: 1rem;
}

.b-button {
    margin-bottom: 1rem;
}

.list-group-item {
    transition: all 1s
}
</style>